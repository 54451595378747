import { useTheme } from "styled-components";
import { convertSnackbarIdToValues } from "../settingsPatterns/settingsPatterns.methods";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useTimeoutSignal } from "./useTimeoutSignal";

export const useTemporaryErrorBySnackbar = (
  errorCondition: boolean,
  callback?: (...args: any[]) => void
): boolean => {
  const {
    globalObjects: { snackbars },
    advanceSettings: {
      settings: { snackbarApiError },
    },
  } = useTheme() as IThemeState;
  const snackbarDisplayTime = convertSnackbarIdToValues(
    snackbarApiError,
    snackbars
  ).timeToDisplay;
  const temporaryError = useTimeoutSignal(
    errorCondition,
    snackbarDisplayTime * 1000,
    callback
  );

  return temporaryError;
};
