import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { IOrderLine } from "@ecp-redux/dto/cart.types";
import { EVENT_ORIGIN, TEventOrigin } from "./const";
import { ICartLineB2B } from "@ecp-redux/dto/cartB2B.types";

export const mapOrderLinesToEvent = (orderLines: IOrderLine[]) =>
  orderLines.map((orderLine) => ({
    product_id: orderLine.productSku,
    quantity: orderLine.quantity,
    net_sales_price: orderLine.nettoPrice,
    sales_price: orderLine.salePrice,
    base_price: orderLine.price,
    suggested_retail_price: orderLine.suggestedRetailPrice,
  }));

export const mapB2BOrderLinesToEvent = (orderLines: ICartLineB2B[]) => {
  if (orderLines.length === 0) return [];
  return orderLines.map((orderLine) => ({
    product_id: orderLine.sku,
    quantity: orderLine.quantity,
    net_sales_price: orderLine.productPriceDetails.netValue,
    sales_price: orderLine.productPriceDetails.grossValue,
  }));
};

export const mapProductsSRPtoEvent = (products: object[]) =>
  (products as { sku: string; [key: string]: unknown }[])
    ?.map(({ sku }) => sku)
    .filter(Boolean) ?? [];

export const extractCategoryFromPath = (path: string) =>
  path.toString().match(/\/(\d+)_/)?.[1] || null;

export const boxTypeToEventOrigin = (
  boxType: TRegularBoxType | TSystemBoxType
): TEventOrigin | null => {
  switch (boxType) {
    case "SEARCH_RESULTS":
      return EVENT_ORIGIN.BOX_SEARCH_RESULTS;
    case "PRODUCT_SLIDER":
      return EVENT_ORIGIN.BOX_PRODUCT_SLIDER;
    case "B2B_ADD_TO_CART_QUANTITY":
      return EVENT_ORIGIN.BOX_B2B_ADD_TO_CART_QUANTITY;
    case "CART_B2B":
      return EVENT_ORIGIN.BOX_CART_B2B;
    case "SEARCH_RESULTS_B2B":
      return EVENT_ORIGIN.BOX_SEARCH_RESULTS_B2B;
    case "GET_TABLE_DATA":
      return EVENT_ORIGIN.BOX_GET_TABLE_DATA;
    case "SYSTEM":
      return EVENT_ORIGIN.BOX_ADD_TO_CART;
    default:
      return null;
  }
};
