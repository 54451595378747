import styled, { css } from "styled-components";
import composeCss from "../../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import { IInputSettings } from "../Inputs";
import { convertInputIdToValues } from "./StyledInput.methods";

export const StyledLine = styled.div`
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
`;

export const StyledInputLabel = styled.label<IInputSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      width: fit-content;
      color: ${convertColorIdToHex(text.labelColor, theme.colorPalette)};
      ${composeCss.font(font.label, theme.typography)};
    `;
  }
);

export const StyledInputValidation = styled.span<IInputSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      width: fit-content;
      ${composeCss.font(font.error, theme.typography)};
      color: ${convertColorIdToHex(text.errorColor, theme.colorPalette)};
    `;
  }
);

export const StyledInputComponent = styled.div<IInputSettings>(
  ({ $settings, theme }) => {
    const { width } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      display: flex;
      flex-direction: column;
      width: ${width}%;
    `;
  }
);

interface IStyledInputSearch extends IInputSettings {
  iconWrapperHeight: number;
}

export const StyledInputSearchComponent = styled.div<IStyledInputSearch>(
  ({ $settings, theme, iconWrapperHeight }) => {
    const { width } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      display: flex;
      width: ${width}%;

      .iconContainer {
        display: flex;
        align-items: center;
        position: absolute;
        height: ${iconWrapperHeight}px;
        right: 15px;
      }
    `;
  }
);

export const StyledInputIcon = styled.div`
  position: relative;
  > input {
    width: 100%;
  }
  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const StyledInput = styled.input.attrs(({ type }) => ({
  type,
}))<IInputSettings>(({ $settings, theme, formNoValidate }) => {
  const inputStyles = convertInputIdToValues(
    $settings,
    theme?.globalObjects?.inputs
  );
  const {
    height,
    font,
    border,
    background,
    text,
    errorBorder,
    activeBorder,
    disabledBorder,
    hoverBorder,
  } = inputStyles;
  return css`
    width: 100%;
    height: ${height}px;
    outline: none;
    padding-left: 10px;
    ${composeCss.borderWidth(border.width)}
    ${composeCss.borderRadius(border.radius)}
    ${composeCss.font(font.input, theme.typography)};
    ${composeCss.borderColor(
      formNoValidate === true || formNoValidate === undefined
        ? border.color
        : errorBorder.color,
      theme
    )}
    ${composeCss.borderStyle(border.style)}
    background-color: ${convertColorIdToHex(
      background.color,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(text.color, theme.colorPalette)};

    &:hover {
      background-color: ${convertColorIdToHex(
        background.hoverColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.hoverColor, theme.colorPalette)};
      ${composeCss.borderColor(hoverBorder.color, theme)}
    }

    &:active,
    &:focus {
      background-color: ${convertColorIdToHex(
        background.activeColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.activeColor, theme.colorPalette)};
      ${composeCss.borderColor(activeBorder.color, theme)}
    }
    &:disabled {
      background-color: ${convertColorIdToHex(
        background.disabledColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.disabledColor, theme.colorPalette)};
      ${composeCss.borderColor(disabledBorder.color, theme)}
    }
    &::placeholder {
      ${composeCss.font(font.placeholder, theme.typography)};
      color: ${convertColorIdToHex(text.placeholderColor, theme.colorPalette)};
    }
  `;
});
