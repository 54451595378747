import {
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  IGlobalInputObject,
  OutlineOptions,
  TColorReadoutValue,
  TInputId,
  TInputReadoutValue,
  TInputSet,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const colorFallback1: TColorReadoutValue = {
  id: "color$$1",
  custom: "#000000",
};

export const typoFallback1: TTypoReadoutValue = {
  id: "typo$$2",
  custom: {
    family: FontFamilyOptions.ROBOTO,
    weight: FontWeightOptionsLongListOfWeights.Weight300,
    size: 24,
    lineHeight: 10,
    letterSpacing: 1,
  },
};

const inputFallback: IGlobalInputObject = {
  width: 80,
  height: 50,
  border: {
    radius: 0,
    width: { left: 10, right: 10, top: 10, bottom: 10 },
    style: OutlineOptions.SOLID,
    color: colorFallback1,
  },
  disabledBorder: {
    radius: 0,
    width: { left: 10, right: 10, top: 10, bottom: 10 },
    style: OutlineOptions.SOLID,
    color: colorFallback1,
  },
  hoverBorder: {
    radius: 0,
    width: { left: 10, right: 10, top: 10, bottom: 10 },
    style: OutlineOptions.SOLID,
    color: colorFallback1,
  },
  activeBorder: {
    radius: 0,
    width: { left: 10, right: 10, top: 10, bottom: 10 },
    style: OutlineOptions.SOLID,
    color: colorFallback1,
  },
  errorBorder: {
    radius: 0,
    width: { left: 10, right: 10, top: 10, bottom: 10 },
    style: OutlineOptions.SOLID,
    color: colorFallback1,
  },
  font: {
    input: typoFallback1,
    label: typoFallback1,
    placeholder: typoFallback1,
    error: typoFallback1,
  },
  background: {
    color: colorFallback1,
    hoverColor: colorFallback1,
    activeColor: colorFallback1,
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  text: {
    color: colorFallback1,
    hoverColor: colorFallback1,
    activeColor: colorFallback1,
    disabledColor: colorFallback1,
    errorColor: colorFallback1,
    placeholderColor: colorFallback1,
    labelColor: colorFallback1,
  },
};

const isInputId = (value: TInputReadoutValue | TInputId): value is TInputId => {
  return typeof (value as TInputId) === "string";
};

export const convertInputIdToValues = (
  value: TInputReadoutValue | TInputId,
  globalInputs?: TInputSet
): IGlobalInputObject => {
  if (value === undefined) return inputFallback;
  if (isInputId(value) && globalInputs !== undefined) {
    return (
      globalInputs?.find((key) => key.id === value) ??
      globalInputs[0] ??
      inputFallback
    );
  } else if (!isInputId(value) && value.id !== "custom") {
    return (
      globalInputs?.find((key) => key.id === value.id) ??
      globalInputs?.[0] ??
      inputFallback
    );
  } else if (!isInputId(value) && value.id === "custom") {
    return value.custom;
  }

  return inputFallback;
};
