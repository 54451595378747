import { ICartB2B } from "@ecp-redux/dto/cartB2B.types";

export const quantityRounding = (
  quantity: number,
  stock: number | null,
  deliveryQuantity?: number
) => {
  return roundWithDeliveryQuantity(quantity, stock, deliveryQuantity ?? 1);
};

const roundWithDeliveryQuantity = (
  quantity: number,
  stock: number | null,
  deliveryQuantity: number
) => {
  if (quantity < deliveryQuantity && (stock === null || quantity < stock)) {
    return deliveryQuantity;
  }

  if (
    quantity > deliveryQuantity &&
    quantity % deliveryQuantity !== 0 &&
    (stock === null || quantity < stock)
  ) {
    const lowerMultiple =
      deliveryQuantity * Math.floor(quantity / deliveryQuantity);
    const higherMultiple =
      deliveryQuantity * Math.ceil(quantity / deliveryQuantity);
    return Math.abs(quantity - lowerMultiple) <
      Math.abs(quantity - higherMultiple)
      ? lowerMultiple
      : higherMultiple;
  }

  if (stock !== null && quantity >= stock) {
    return Math.floor(stock / deliveryQuantity) * deliveryQuantity;
  }

  return quantity;
};

export const stockDisplaying = (quantity: number, packageCapacity: number) => {
  if (!packageCapacity || packageCapacity === 1) {
    return quantity;
  }

  const packages = Math.floor(quantity / packageCapacity);
  const restFromDivision = quantity % packageCapacity;

  if (restFromDivision !== 0) {
    return `${packages} + ${restFromDivision}`;
  }

  return packages;
};

export const useCartB2B_Mock = () => {
  const addToCart = (sku: string, quantity: number, packageType: string) =>
    console.log("addToCart: ", sku, quantity, packageType);
  const isLoading = false;

  return { addToCart, isLoading };
};

export const findCartLineBySku = (
  cartData: ICartB2B | undefined,
  product?: {
    sku: string;
    deliveryQuantity?: number;
    unitOfMeasure?: {
      currentUnitOfMeasure: string;
      availableUnitsOfMeasure: {
        unitOfMeasure: string;
        unitOfMeasureLabel: string;
        unitOfMeasureQuantity: number;
      }[];
    };
  }
) => {
  if (cartData !== undefined && product !== undefined) {
    for (const order of cartData.orders) {
      const cartLine = order.cartLines.find((line) => line.sku === product.sku);
      if (cartLine) {
        return cartLine;
      }
    }
  }

  return {
    quantity: 0,
    deliveryQuantity: product?.deliveryQuantity ?? 1,
    unitOfMeasure: product?.unitOfMeasure
      ? {
          ...product.unitOfMeasure,
          baseUnitOfMeasure: product.unitOfMeasure.currentUnitOfMeasure,
        }
      : {
          baseUnitOfMeasure: "",
          currentUnitOfMeasure: "",
          availableUnitsOfMeasure: [],
        },
    stock: 500, // this value should be replaced with stock value from SRP
  };
};

export const getProductType = (cartData: ICartB2B | undefined, sku: string) =>
  cartData?.orders?.find((order) =>
    order.cartLines.find((cartLine) => cartLine.sku === sku)
  )?.productType;
