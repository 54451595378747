import styled, { css } from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
  TInputId,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertInputIdToValues } from "../../Input/StyledInput/StyledInput.methods";
import { alignmentOptionsToValue } from "../../../../settingsPatterns/composeCss";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";

export const StyledChangeProductQuantityB2B = styled.div<{
  inputStyle: TInputId | TInputReadoutValue;
  spaceBetweenInputButtons: number;
  boxType?: TRegularBoxType | TSystemBoxType;
  padding?: ISpacing;
  alignment?: AlignmentHorizontalOption;
}>(
  ({
    inputStyle,
    spaceBetweenInputButtons,
    theme,
    boxType,
    padding,
    alignment,
  }) => {
    const { width } = convertInputIdToValues(
      inputStyle,
      theme.globalObjects?.inputs
    );

    return css`
      display: flex;
      align-items: center;

      ${(boxType === "PRODUCT_SLIDER" || boxType === "SEARCH_RESULTS") &&
      padding &&
      alignment
        ? css`
            width: 100%;
            justify-content: ${alignmentOptionsToValue(alignment)};
            & > input {
              margin-left: ${padding.left}px;
              margin-right: ${padding.right}px;
            }
            & > button {
              width: min-content;
            }
          `
        : css`
            gap: ${spaceBetweenInputButtons}px;
          `}
      .change-product-quantity-b2b {
        &__count-input {
          text-align: center;
          padding-right: 10px;
          width: ${width}%;
        }

        &__plus-button,
        &__minus-button {
          cursor: pointer;
        }
      }
    `;
  }
);
