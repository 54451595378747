import { useState, useEffect } from "react";

export const useTimeoutSignal = (
  trigger: boolean,
  timeout: number,
  callback?: (...args: any[]) => void
): boolean => {
  const [showSignal, setShowSignal] = useState(false);

  useEffect(() => {
    if (trigger) {
      setShowSignal(true);
    }
  }, [trigger]);

  useEffect(() => {
    if (showSignal) {
      setTimeout(() => {
        setShowSignal(false);
        callback?.();
      }, timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSignal]);

  return showSignal;
};
